import { isReceivingAudio, isReceivingVideo, isSendingAudio, isSendingVideo } from "@pexip/infinity";
import { computed, ref } from "vue";

import { useScreenOrientation } from "@/composables";
import { getClientCallTypeFromCallType } from "@/helpers";
import { useUrlParams } from "@/stores";

const cameraOff = ref(false);
const microphoneOff = ref(false);
const aspectPortrait = 9 / 16;
const aspectLandscape = 16 / 9;

export const useStream = () => {
  const { isPortrait } = useScreenOrientation();
  const urlParams = useUrlParams();

  const clientCallType = computed(() => getClientCallTypeFromCallType(urlParams.callType));
  const canReceiveAudio = computed(() => isReceivingAudio(clientCallType.value));
  const canReceiveVideo = computed(() => isReceivingVideo(clientCallType.value));
  const canSendAudio = computed(() => isSendingAudio(clientCallType.value));
  const canSendVideo = computed(() => isSendingVideo(clientCallType.value));

  const preferredAspectRatio = computed(() => {
    return isPortrait.value ? aspectPortrait : aspectLandscape;
  });

  const videoConstraints = computed<MediaTrackConstraints>(() => {
    return {
      height: { ideal: 720, max: 1080 },
      width: { ideal: 1280, max: 1920 },
      facingMode: { ideal: isPortrait.value ? "user" : "environment" }
    };
  });

  const getStream = async (
    videoInputDeviceId: string | undefined,
    audioInputDeviceId: string | undefined,
    ignoreSettings = false,
    overrideConstraints?: MediaStreamConstraints
  ) => {
    if (!videoInputDeviceId && !audioInputDeviceId) {
      return undefined;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        overrideConstraints ?? {
          video:
            videoInputDeviceId && canSendVideo.value
              ? {
                  ...videoConstraints.value,
                  deviceId: { exact: videoInputDeviceId }
                }
              : false,
          audio:
            audioInputDeviceId && canSendAudio.value
              ? {
                  deviceId: { exact: audioInputDeviceId }
                }
              : false
        }
      );

      if (!ignoreSettings) {
        if (microphoneOff.value) {
          stream.getAudioTracks().forEach((track) => {
            track.enabled = false;
          });
        }

        if (cameraOff.value) {
          stream.getVideoTracks().forEach((track) => {
            track.enabled = false;
          });
        }
      }

      return stream;
    } catch (err) {
      console.error("Error getting media devices", err);
    }
  };

  const streamAspectRatio = (stream: MediaStream) => {
    const track = stream.getVideoTracks()[0];

    if (!track) {
      return aspectLandscape;
    }

    const { width, height } = track.getSettings();

    if (width && height) {
      return width > height ? aspectLandscape : aspectPortrait;
    }

    return preferredAspectRatio.value;
  };

  return {
    videoConstraints,
    preferredAspectRatio,
    clientCallType,
    canSendAudio,
    canReceiveAudio,
    canReceiveVideo,
    canSendVideo,
    cameraOff,
    microphoneOff,
    aspectLandscape,
    aspectPortrait,
    streamAspectRatio,
    getStream
  };
};
