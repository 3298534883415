import { storeToRefs } from "pinia";
import { type NavigationGuard } from "vue-router";

import { extractAlias } from "@/helpers";
import { useUrlParams } from "@/stores";

export const appInitGuard: NavigationGuard = (to) => {
  const { setParams, resetParams, setUrlDevices } = useUrlParams();
  const { startsAt } = storeToRefs(useUrlParams());
  const { alias } = to.params;
  const { conference, url } = to.query;

  resetParams();

  const meetingAlias = conference || alias || url;
  const meeting = {
    alias: meetingAlias?.toString() ?? "",
    host: meetingAlias ? extractAlias(meetingAlias.toString(), 1) : window.location.host
  };

  if (Object.keys(to.query).length) {
    setUrlDevices(to.query);
    setParams({
      ...to.query,
      alias: meeting.alias,
      host: meeting.host
    });
  }

  const currentDate = new Date();
  const startDate = new Date(startsAt.value ? startsAt.value : currentDate); // Handle undefined start_dato

  // Check if start_dato is in the future and redirect to waiting-room
  // Avoid redirecting if already on the waiting-room route
  if (startDate > currentDate && to.path !== `/${meeting.alias}/waiting-room`) {
    return {
      name: "waiting-room",
      params: { alias: meeting.alias },
      query: undefined
    };
  }

  if (to.path !== `/${meeting.alias}` && to.path !== `/${meeting.alias}/call`) {
    return {
      name: "preflight-alias",
      params: { alias: meeting.alias },
      query: undefined
    };
  }

  if (Object.keys(to.query).length > 0) {
    return { query: undefined };
  }
};
