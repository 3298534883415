import { storeToRefs } from "pinia";
import type { NavigationGuard } from "vue-router";

import { extractAlias } from "@/helpers";
import { useUrlParams } from "@/stores";

export const meetingRoomGuard: NavigationGuard = (to) => {
  const { setParams } = useUrlParams();
  const { startsAt } = storeToRefs(useUrlParams());

  const alias = to.params.alias;
  const name = to.name;
  const query = to.query;

  const meetingRoom = {
    alias: alias?.toString() ?? "",
    host: alias ? extractAlias(alias.toString(), 1) : ""
  };

  setParams({
    ...query,
    ...meetingRoom
  });

  if (Object.keys(query).length > 0) {
    if (new Date(startsAt.value) > new Date() && to.path !== `/${meetingRoom.alias}/waiting-room`) {
      return {
        name: "waiting-room",
        params: { alias: meetingRoom.alias },
        query: undefined
      };
    } else {
      return {
        name,
        params: { alias: meetingRoom.alias },
        query: undefined
      };
    }
  }
};
