<script setup lang="ts">
import { onBeforeMount } from "vue";
import { RouterView } from "vue-router";

import ThemeBackground from "./components/ThemeBackground.vue";
import ToastNotification from "./components/ToastNotification.vue";
import { useTheme } from "./composables";
import { useDeviceManagement } from "./stores";

const { theme } = useTheme();
const { requestPermissions } = useDeviceManagement();

onBeforeMount(async () => {
  document.documentElement.setAttribute("data-theme", theme.value);
  try {
    await requestPermissions();
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <main class="relative">
    <RouterView />
    <ToastNotification />
    <ThemeBackground />
  </main>
</template>
