<template>
  <svg
    class="absolute -z-10 top-0 left-0 h-viewport max-w-full"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 800"
    preserveAspectRatio="xMidYMid slice"
    width="100%"
    height="100%"
  >
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color: var(--primary-color); stop-opacity: 1" />
        <stop offset="100%" style="stop-color: var(--secondary-color); stop-opacity: 1" />
      </linearGradient>
    </defs>

    <rect width="100%" height="100%" fill="url(#grad1)" />

    <circle cx="400" cy="200" r="300" fill="rgba(255,255,255, var(--circle-opacity))">
      <animateTransform attributeName="transform" type="scale" values="1; 1.05; 1" dur="10s" repeatCount="indefinite" />
    </circle>

    <circle cx="1200" cy="600" r="250" fill="rgba(255,255,255, var(--circle-opacity-2))">
      <animateTransform attributeName="transform" type="scale" values="1; 1.03; 1" dur="12s" repeatCount="indefinite" />
    </circle>

    <path d="M0 400 Q720 100, 1440 400 T1440 800 L0 800 Z" fill="rgba(255,255,255, var(--wave-opacity))">
      <animate
        attributeName="d"
        values="M0 400 Q720 100, 1440 400 T1440 800 L0 800 Z;
                  M0 420 Q720 120, 1440 420 T1440 800 L0 800 Z;
                  M0 400 Q720 100, 1440 400 T1440 800 L0 800 Z"
        dur="13s"
        repeatCount="indefinite"
      />
    </path>

    <rect x="800" y="100" width="200" height="100" rx="30" fill="rgba(255,255,255, var(--rect-opacity))">
      <animate attributeName="x" values="800; 850; 800" dur="10s" repeatCount="indefinite" />
    </rect>

    <polygon points="1000,350 1100,500 900,500" fill="rgba(255,255,255, var(--polygon-opacity))">
      <animateMotion dur="20s" repeatCount="indefinite" path="M 25 25 A 25 25 0 1 1 24 25" />
    </polygon>
  </svg>
</template>

<style lang="scss">
:root {
  --circle-opacity: 0.12;
  --circle-opacity-2: 0.07;
  --wave-opacity: 0.2;
  --rect-opacity: 0.3;
  --polygon-opacity: 0.25;
}
</style>
