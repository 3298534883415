import { ClientCallType } from "@pexip/infinity";

import type { CallType } from "@/types";

const callTypeMapping: Record<CallType, ClientCallType> = {
  none: ClientCallType.None,
  audiosendonly: ClientCallType.AudioSendOnly,
  audiorecvonly: ClientCallType.AudioRecvOnly,
  videosendonly: ClientCallType.VideoSendOnly,
  videorecvonly: ClientCallType.VideoRecvOnly,
  audioonly: ClientCallType.Audio,
  video: ClientCallType.Video,
  audiovideosendonly: ClientCallType.AudioSendVideoSendOnly,
  audiovideorecvonly: ClientCallType.AudioRecvVideoRecvOnly,
  audiovideo: ClientCallType.AudioVideo
};

export const getClientCallTypeFromCallType = (callType: CallType) => {
  return callTypeMapping[callType];
};

export const isValidCallType = (callTypeStr: string): callTypeStr is CallType => {
  const validCallTypes: CallType[] = [
    "none",
    "audiosendonly",
    "audiorecvonly",
    "videosendonly",
    "videorecvonly",
    "audioonly",
    "video",
    "audiovideosendonly",
    "audiovideorecvonly",
    "audiovideo"
  ];

  return (validCallTypes as string[]).includes(callTypeStr);
};
