<script setup lang="ts">
import { useToast } from "@/stores/";

import ToastCard from "./ToastCard.vue";

const toastStore = useToast();
</script>

<template>
  <Teleport to="body">
    <Transition name="toast">
      <div v-if="toastStore.toasts.length" class="fixed bottom-4 right-4 z-50 ml-4 max-w-96">
        <TransitionGroup name="toast" tag="ul" class="flex flex-col gap-2">
          <template v-for="toast in toastStore.toasts" :key="toast.id">
            <ToastCard :toast="toast" />
          </template>
        </TransitionGroup>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.toast-enter-from,
.toast-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.toast-enter-active,
.toast-leave-active {
  transition: 0.25s ease all;
}
</style>
