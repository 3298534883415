import { createRouter, createWebHistory } from "vue-router";

import { appInitGuard, meetingRoomGuard, waitingRoomGuard } from "@/router/guards";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "preflight",
      component: () => import("@/views/PreFlight.vue"),
      beforeEnter: [appInitGuard]
    },
    {
      path: "/:alias",
      name: "preflight-alias",
      component: () => import("@/views/PreFlight.vue"),
      beforeEnter: [meetingRoomGuard]
    },
    {
      path: "/:alias/waiting-room",
      name: "waiting-room",
      component: () => import("@/views/WaitingRoom.vue"),
      beforeEnter: [waitingRoomGuard]
    },
    {
      path: "/:alias/call",
      name: "call",
      component: () => import("@/views/ConferenceCall.vue"),
      beforeEnter: [meetingRoomGuard]
    }
  ]
});
