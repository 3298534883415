import "@/styles/main.scss";

import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import App from "@/App.vue";
import { PexipLoggers } from "@/helpers";
import { i18n } from "@/i18n";
import { router } from "@/router";
import { pinia } from "@/stores";

const app = createApp(App);

if (import.meta.env.PUBLIC_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.extraErrorDataIntegration({ captureErrorCause: true }),
      Sentry.httpClientIntegration()
    ],
    sendDefaultPii: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  Sentry.setContext("app", {
    version: import.meta.env.PACKAGE_VERSION
  });
}

// Override Pexip loggers in production
if (import.meta.env.PROD) {
  const logger = PexipLoggers.createLogger({
    error(metadata, message) {
      Sentry.captureEvent({
        message,
        extra: { metadata },
        level: "error"
      });

      console.error(message, metadata);
    },
    fatal(metadata, message) {
      Sentry.captureEvent({
        message,
        extra: { metadata },
        level: "fatal"
      });

      console.error(message, metadata);
    }
  });

  PexipLoggers.setLoggers(logger);
}

app.use(i18n).use(pinia).use(router).mount("#app");
