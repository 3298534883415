import { ref } from "vue";

type Theme = "region-syd" | "region-midtjylland" | "region-hovedstaden" | "default";

export const useTheme = () => {
  const getThemeBasedOnHost = (host = window.location.hostname) => {
    if (host.includes("rs.")) {
      return "region-syd";
    } else if (host.includes("rh.")) {
      return "region-hovedstaden";
    } else if (host.includes("rm.")) {
      return "region-midtjylland";
    } else {
      return "default";
    }
  };

  const theme = ref<Theme>(getThemeBasedOnHost());

  return {
    theme
  };
};
