<script setup lang="ts">
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import Fa from "vue-fa";

import { type Toast, type ToastStatus } from "@/stores/";

const { toast } = defineProps<{ toast: Toast }>();

const toastClassMap: Record<ToastStatus, string> = {
  warning: "border-yellow-500 text-yellow-500",
  error: "border-red-500 text-red-500",
  success: "border-green-500 text-green-500",
  info: "border-blue-500 text-blue-500"
};

const toastIconMap: Record<ToastStatus, IconDefinition> = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  success: faCheck,
  info: faCircleInfo
};
</script>

<template>
  <li class="flex items-center gap-4 rounded border-2 bg-white p-4" :class="toastClassMap[toast.status]">
    <Fa class="aspect-square" size="1.5x" :icon="toastIconMap[toast.status]" />
    <div class="grid gap-2">
      <span class="text-md font-semibold">
        {{ toast.text || toast.error }}
      </span>
      <span v-if="toast.text" class="text-xs font-normal">
        {{ toast.error }}
      </span>
    </div>
  </li>
</template>
