import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, type MaybeRefOrGetter, ref, toValue } from "vue";

import { useDevicesList, useStream } from "@/composables";

/**
 * Composable that returns a media device from the provided list of devices.
 * It optionally uses a preferred device ID to prioritize a specific device. If a preferred device
 * ID is not provided or is not found, the first available device in the list will be returned as fallback.
 */
function useDevice(devices: MaybeRefOrGetter<MediaDeviceInfo[]>, preferredDeviceId = ref<string>()) {
  const device = computed(() => {
    return toValue(devices).find(({ deviceId }) => {
      return !preferredDeviceId.value || deviceId === preferredDeviceId.value;
    });
  });

  const deviceId = computed(() => device.value?.deviceId);

  return {
    preferredDeviceId,
    device,
    deviceId
  };
}

export const useDeviceManagement = defineStore(
  "device-management",
  () => {
    const { videoConstraints, canSendAudio, canSendVideo } = useStream();

    const {
      videoInputs,
      audioInputs,
      audioOutputs,
      isPermissionsGranted,
      cameraAvailable,
      microphoneAvailable,
      requestPermissions
    } = useDevicesList({
      constraints: {
        video: canSendVideo.value ? videoConstraints.value : false,
        audio: canSendAudio.value
      }
    });

    const { preferredDeviceId: preferredVideoInputDeviceId, deviceId: videoInputDeviceId } = useDevice(videoInputs);
    const { preferredDeviceId: preferredAudioInputDeviceId, deviceId: audioInputDeviceId } = useDevice(audioInputs);
    const { preferredDeviceId: preferredAudioOutputDeviceId, deviceId: audioOutputDeviceId } = useDevice(audioOutputs);

    return {
      preferredVideoInputDeviceId,
      preferredAudioInputDeviceId,
      preferredAudioOutputDeviceId,
      videoInputDeviceId,
      audioInputDeviceId,
      audioOutputDeviceId,
      isPermissionsGranted,
      cameraAvailable,
      microphoneAvailable,
      requestPermissions
    };
  },
  {
    persist: {
      storage: localStorage,
      pick: ["preferredVideoInputDeviceId", "preferredAudioInputDeviceId", "preferredAudioOutputDeviceId"]
    }
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceManagement, import.meta.hot));
}
