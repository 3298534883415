import { useEventListener, usePermission } from "@vueuse/core";
import { computed, ref, watch } from "vue";

export interface UseDevicesListOptions {
  constraints?: MediaStreamConstraints;
}

export function useDevicesList(options: UseDevicesListOptions = {}) {
  const {
    constraints = {
      audio: true,
      video: true
    }
  } = options;

  const { state: cameraState } = usePermission("camera", { controls: true });
  const { state: microphoneState } = usePermission("microphone", { controls: true });

  const isPermissionsGranted = computed(() => {
    if (constraints.video && cameraState.value !== "granted") {
      return false;
    }

    if (constraints.audio && microphoneState.value !== "granted") {
      return false;
    }

    return true;
  });

  const devices = ref<MediaDeviceInfo[]>([]);
  const videoInputs = computed(() => devices.value.filter((device) => device.kind === "videoinput"));
  const audioInputs = computed(() => devices.value.filter((device) => device.kind === "audioinput"));
  const audioOutputs = computed(() => devices.value.filter((device) => device.kind === "audiooutput"));
  const cameraAvailable = ref(false);
  const microphoneAvailable = ref(false);

  async function updateDevices(allowRequestPermissions = false) {
    // Browsers require getUserMedia() to be called before enumerateDevices() to prevent fingerprinting,
    // which used to be a method for tracking users by calling enumerateDevices en masse without user consent.
    // To enhance user privacy, enumerating devices first, without user permission, no longer lists all devices.
    if (isPermissionsGranted.value || allowRequestPermissions) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints).catch(async (error) => {
          console.error(error);

          // If permissions are granted, try to get the devices again but separately
          // This is to prevent the case where no devices are listed when permissions are granted
          // Example: OS settings are not permitted to access camera or microphone but the user has granted permissions in browser
          if (isPermissionsGranted.value) {
            const [audioOnly, videoOnly] = await Promise.allSettled([
              navigator.mediaDevices.getUserMedia({ audio: true }),
              navigator.mediaDevices.getUserMedia({ video: true })
            ]);

            if (audioOnly.status === "fulfilled") {
              audioOnly.value.getTracks().forEach((track) => track.stop());
              microphoneAvailable.value = true;
            }

            if (videoOnly.status === "fulfilled") {
              videoOnly.value.getTracks().forEach((track) => track.stop());
              cameraAvailable.value = true;
            }
          }
        });

        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
          cameraAvailable.value = true;
          microphoneAvailable.value = true;
        }
      } catch (err) {
        console.error(err);
      }
    }

    devices.value = await navigator.mediaDevices.enumerateDevices();
  }

  async function requestPermissions() {
    await updateDevices(true);
  }

  useEventListener(navigator.mediaDevices, "devicechange", () => updateDevices());
  watch(isPermissionsGranted, () => updateDevices(), { immediate: true });

  return {
    devices,
    videoInputs,
    audioInputs,
    audioOutputs,
    isPermissionsGranted,
    cameraAvailable,
    microphoneAvailable,
    requestPermissions
  };
}
