import { storeToRefs } from "pinia";
import { type NavigationGuard } from "vue-router";

import { useUrlParams } from "@/stores";

export const waitingRoomGuard: NavigationGuard = (to) => {
  const {
    params: { alias },
    name,
    query
  } = to;
  const { startsAt } = storeToRefs(useUrlParams());

  if (!startsAt.value) {
    return {
      name: "preflight"
    };
  }

  if (Object.keys(query).length > 0) {
    return {
      name,
      params: { alias: alias?.toString() },
      query: undefined
    };
  }
};
