import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

type ToastPayload = { timeout?: number; text: string; error?: string };

export type ToastStatus = "success" | "warning" | "error" | "info";

export interface Toast {
  text: string;
  error?: string;
  status: ToastStatus;
  id: number;
}

export const useToast = defineStore("toaster", () => {
  const toasts = ref<Toast[]>([]);
  const defaultTimeout: number = 5000;

  const createToast = (text: string, status: ToastStatus, error?: string): Toast => ({
    text,
    error,
    status,
    id: Math.random() * 1000
  });

  const noDuplicatedToast = (toast: Toast) => {
    return toasts.value.find((t) => t.error === toast.error) === undefined;
  };

  const updateState = (payload: ToastPayload, status: ToastStatus) => {
    const { text, timeout, error } = payload;
    const toast = createToast(text, status, error);

    if (noDuplicatedToast(toast)) {
      toasts.value.push(toast);
    }

    setTimeout(() => {
      toasts.value = toasts.value.filter((t) => t.id !== toast.id);
    }, timeout ?? defaultTimeout);
  };

  const toastSuccess = (payload: ToastPayload) => {
    updateState(payload, "success");
  };

  const toastWarning = (payload: ToastPayload) => {
    updateState(payload, "warning");
  };

  const toastError = (payload: ToastPayload) => {
    updateState(payload, "error");
  };

  const toastInfo = (payload: ToastPayload) => {
    updateState(payload, "info");
  };

  return {
    toasts,
    toastError,
    toastWarning,
    toastSuccess,
    toastInfo
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useToast, import.meta.hot));
}
