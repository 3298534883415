import { createI18n } from "vue-i18n";

import da from "./locales/da.json";

export type MessageSchema = typeof da;

const messages: Record<string, MessageSchema> = {
  da
};

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("chosenLanguage") ?? "da",
  fallbackLocale: "da",
  warnHtmlMessage: false,
  allowComposition: true,
  globalInjection: false,
  messages
});
