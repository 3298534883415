import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { computed, reactive, toRefs } from "vue";
import type { LocationQuery } from "vue-router";

import { getEntries, isValidCallType } from "@/helpers";
import type { CallType } from "@/types";

import { useDeviceManagement } from "./use-device-management";

type ParamOptions = {
  alias: string;
  host: string;
  name: string;
  pin: string;
  role: string;
  redirectTo: string;
  muteMicrophone: boolean;
  muteCamera: boolean;
  callTag: string;
  callType: CallType;
  extension: string;
  bandwidth: string;
  startsAt: string;
  join: 1 | 0;
};

const defaultStore: ParamOptions = {
  alias: "",
  host: window.location.host,
  name: "",
  pin: "",
  role: "",
  redirectTo: "",
  muteMicrophone: false,
  muteCamera: false,
  callTag: "",
  callType: "audiovideo",
  extension: "",
  bandwidth: "",
  startsAt: "",
  join: 0
} as const;

// url test
// https://localhost:5173/medware@rooms.vconf.dk?pin=1020&muteMicrophone=true
// Replaces alias with conference
// /medware@rooms.vconf.dk?pin=1020&muteMicrophone=true&name=Andreas&conference=meet.alice@pexip.com
// Uses conference as alias
// ?pin=1020&muteMicrophone=true&name=Andreas&conference=medware@rooms.vconf.dk
// Has a start date in the future and conference
// ?pin=1020&muteMicrophone=true&name=Andreas&start_date=2024-10-30T12:51:54&conference=meet.alice@pexip.com

export const useUrlParams = defineStore(
  "url-params",
  () => {
    const params = reactive<ParamOptions>({ ...defaultStore });
    const meetingRoomPath = computed(() => {
      const { alias, name, pin, role, muteMicrophone, muteCamera, extension, bandwidth, startsAt, join } = params;

      const queryParams = [
        `name=${name}`,
        `pin=${pin}`,
        `role=${role}`,
        `muteMicrophone=${muteMicrophone}`,
        `muteCamera=${muteCamera}`,
        `extension=${extension}`,
        `bandwidth=${bandwidth}`,
        `join=${join ? 1 : startsAt ? 1 : 0}`
      ];

      const nonEmptyParams = queryParams.filter((param) => param.split("=")[1]);

      const url = `/${alias}/?${nonEmptyParams.join("&")}`;

      return url;
    });

    const setParams = (locationQuery: LocationQuery) => {
      const meetingStart = locationQuery.startsAt ?? locationQuery.start_dato;
      const autoJoin = locationQuery.join?.toString() === "1" ? 1 : meetingStart?.toString() ? 1 : undefined;
      const redirectPath = locationQuery.redirectTo?.toString() ?? locationQuery.redirect_url?.toString();
      const microphoneMuted =
        (locationQuery.microphone && locationQuery.microphone !== "on") ||
        (locationQuery.muteMicrophone && locationQuery.muteMicrophone === "true");
      const cameraMuted =
        locationQuery.muteCamera !== undefined ? locationQuery.muteCamera?.toString() === "true" : undefined;

      const entries = {
        alias: locationQuery.alias?.toString(),
        host: locationQuery.host?.toString(),
        name: locationQuery.name?.toString(),
        pin: locationQuery.pin?.toString(),
        role: locationQuery.role?.toString(),
        extension: locationQuery.extension?.toString(),
        bandwidth: locationQuery.bandwidth?.toString(),
        callType: locationQuery.callType?.toString(),
        callTag: locationQuery.callTag?.toString(),
        startsAt: meetingStart?.toString(),
        redirectTo: redirectPath,
        muteMicrophone: microphoneMuted,
        muteCamera: cameraMuted,
        join: autoJoin
      };

      const updatedParams = getEntries(entries).filter(([, value]) => value !== undefined);

      Object.assign(params, Object.fromEntries(updatedParams));

      ["callTag", "callType", "extension", "bandwidth"].forEach((param) => {
        if (locationQuery[param] && param === "callType") {
          const paramsCallType = locationQuery[param].toString();

          if (paramsCallType !== "videosendonly") {
            params.callType = defaultStore.callType;

            if (!isValidCallType(paramsCallType)) {
              notValid(paramsCallType, param);
            } else {
              notImplemented(paramsCallType);
            }
          }
        } else if (locationQuery[param]) {
          notImplemented(param);
        }
      });
    };

    const notImplemented = (prop: string) => {
      console.warn(`Warning: ´${prop}´ is not supported yet in this version.`);
    };

    const notValid = (prop: string, param: string) => {
      console.warn(`´${prop}´ is not a valid value for ${param}`);
    };

    const setUrlDevices = (locationQuery: LocationQuery) => {
      // Misspelling of preferred is intentional, the old query param
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { devices, preffered_camera, preffered_microphone, preffered_speaker } = locationQuery;

      const urlDevicesBase64Decoded = devices ? atob(devices.toString()).split(",") : [];

      const urlDevices = urlDevicesBase64Decoded.length
        ? urlDevicesBase64Decoded
        : [
            preffered_camera?.toString() ?? "",
            preffered_microphone?.toString() ?? "",
            preffered_speaker?.toString() ?? ""
          ];

      if (urlDevices.length > 1) {
        const { preferredVideoInputDeviceId, preferredAudioInputDeviceId, preferredAudioOutputDeviceId } =
          storeToRefs(useDeviceManagement());

        const [videoInputDeviceId, audioInputDeviceId, audioOutputDeviceId] = urlDevices;

        preferredVideoInputDeviceId.value = videoInputDeviceId;
        preferredAudioInputDeviceId.value = audioInputDeviceId;
        preferredAudioOutputDeviceId.value = audioOutputDeviceId;
      }
    };

    const setJoinParam = (join: 1 | 0) => {
      params.join = join;
    };

    const setMuteCamera = (mute: boolean) => {
      params.muteCamera = mute;
    };

    const setMuteMicrophone = (mute: boolean) => {
      params.muteMicrophone = mute;
    };

    const resetParams = () => {
      Object.assign(params, defaultStore);
    };

    return {
      ...toRefs(params),
      meetingRoomPath,
      setParams,
      resetParams,
      setUrlDevices,
      setJoinParam,
      setMuteCamera,
      setMuteMicrophone
    };
  },
  {
    persist: true
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUrlParams, import.meta.hot));
}
