import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";

export const pinia = createPinia().use(
  createPersistedState({
    storage: sessionStorage
  })
);

export * from "./use-conference";
export * from "./use-device-management";
export * from "./use-me";
export * from "./use-presentation";
export * from "./use-splash-screen";
export * from "./use-toast";
export * from "./use-url-params";
export * from "./use-video-layout";
