import { useMediaQuery } from "@vueuse/core";
import { computed } from "vue";

export function useScreenOrientation() {
  const isPortrait = useMediaQuery("(orientation: portrait)");
  const isLandscape = computed(() => !isPortrait.value);

  return {
    isPortrait,
    isLandscape
  };
}
